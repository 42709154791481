import { t } from "i18next";

export const Unauthorized = () => {
  return (
    <>
      <div className="flex flex-column justify-content-center align-items-center min-h-screen">
        <span className="text-900 text-4xl font-bold mb-2">
          {t("app.unauthorized.title")}
        </span>
        <span className="text-600 text-xl mb-4">
          {t("app.unauthorized.message")}
        </span>
        <a href="/login" className="text-600 font-medium">
          {t("app.unauthorized.login")}
        </a>
      </div>
    </>
  );
};
