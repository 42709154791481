import React, { useContext } from "react";
import { NotificationContext } from "../context/NotificationContext";
import { NotificationToast } from "./NotificationToast";

const NotificationDisplay = () => {
  const { notifications, removeNotification } = useContext(NotificationContext);

  return (
    <>
      {" "}
      {notifications.map((notification) => (
        <NotificationToast notification={notification} />
      ))}{" "}
    </>
  );
};

export default NotificationDisplay;
