import React, { createContext, ReactNode, useState } from "react";
import { useQuery } from "react-query";
import { useApis } from "../api";

export interface Language {
  id: number;
  name: string;
  code: string;
}

export interface Timezone {
  id: number;
  name: string;
  country_id?: number;
}

export interface City {
  id: number;
  name: string;
  province_id: number;
}

export interface Province {
  id: number;
  name: string;
  region_id: number;
  code: string;
  province_group_id?: number;
}

export interface Region {
  id: number;
  name: string;
  country_id: number;
  middlewareCode?: string;
}

export interface Country {
  id: number;
  name: string;
  country_group_id?: number;
  code: string;
  phoneCode?: string;
}
export interface CountryGroup {
  id: number;
  name: string;
  countries?: Country[];
}

export interface ProvinceGroup {
  name: string;
  provinces: string[];
}

export interface ResourceContextProps {
  countryGroups: CountryGroup[];
  countries: Country[];
  regions: Region[];
  provinces: Province[];
  cities: City[];
  timezones: Timezone[];
  languages: Language[];
  provinceGroups: ProvinceGroup[];
  mailLanguages: { label: string; value: string }[];
}

const provinceGroups = [
  {
    name: "Nord Ovest (AO, MS, LU, PI, AL, AT, BI, CN, NO, TO, VB, VC, GE, IM, SP, SV)",
    provinces: [
      "AO",
      "MS",
      "LU",
      "PI",
      "AL",
      "AT",
      "BI",
      "CN",
      "NO",
      "TO",
      "VB",
      "VC",
      "GE",
      "IM",
      "SP",
      "SV",
    ],
  },
  {
    name: "Nord Est (BZ, TN, VA, CO, PV, CR, LC, BG, BS, LO, MN, MB)",
    provinces: [
      "BZ",
      "TN",
      "VA",
      "CO",
      "PV",
      "CR",
      "LC",
      "BG",
      "BS",
      "LO",
      "MN",
      "MB",
    ],
  },
  {
    name: "Sud (CH, AQ, PE, TE, MT, PZ, AV, BN, NA, SA, CE, FR, LT, RI, CB, IS, PG, TR, VT)",
    provinces: [
      "CH",
      "AQ",
      "PE",
      "TE",
      "MT",
      "PZ",
      "AV",
      "BN",
      "NA",
      "SA",
      "CE",
      "FR",
      "LT",
      "RI",
      "CB",
      "IS",
      "PG",
      "TR",
      "VT",
    ],
  },
];

const mailLanguages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "it",
    label: "Italiano",
  },
];

export const ResourceContext = createContext<ResourceContextProps>({
  countryGroups: [],
  countries: [],
  regions: [],
  provinces: [],
  cities: [],
  timezones: [],
  languages: [],
  provinceGroups: [],
  mailLanguages: [],
});

interface ResourceProviderProps {
  children: ReactNode;
}

export const ResourceProvider: React.FC<ResourceProviderProps> = ({
  children,
}) => {
  const {
    getCountryGroups,
    getCountries,
    getRegions,
    getProvinces,
    getCities,
    getTimezones,
    getLanguages,
  } = useApis();

  const [countryGroups, setCountryGroups] = useState<CountryGroup[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  useQuery("countryGroups", getCountryGroups, {
    onSuccess: (data) => {
      setCountryGroups(data?.data);
    },
  });

  useQuery("countries", getCountries, {
    onSuccess: (data) => {
      setCountries(data?.data);
    },
  });

  useQuery("regions", getRegions, {
    onSuccess: (data) => {
      setRegions(data?.data);
    },
  });

  useQuery("provinces", getProvinces, {
    onSuccess: (data) => {
      setProvinces(data?.data);
    },
  });

  useQuery("cities", getCities, {
    onSuccess: (data) => {
      setCities(data?.data);
    },
  });

  useQuery("timezones", getTimezones, {
    onSuccess: (data) => {
      setTimezones(data?.data);
    },
  });

  useQuery("languages", getLanguages, {
    onSuccess: (data) => {
      setLanguages(data?.data);
    },
  });

  return (
    <ResourceContext.Provider
      value={{
        countryGroups,
        countries,
        regions,
        provinces,
        cities,
        timezones,
        languages,
        provinceGroups,
        mailLanguages: mailLanguages,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};
