import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { Notification } from "../context/NotificationContext";

export const NotificationToast = (props: { notification: Notification }) => {
  const toast = useRef<any>(null);

  const hasBeenShown = useRef(false);

  useEffect(() => {
    if (toast.current && !hasBeenShown.current) {
      hasBeenShown.current = true;
      setTimeout(() => {
        toast.current?.show({
          severity: props.notification.type,
          summary: props.notification.title,
          detail: props.notification.message,
          life: 4000,
        });
      }, 100);
    }
  }, [props.notification]);

  return <Toast ref={toast} />;
};
