import { API_BASE_URL } from "../utils/mocks";
import { useAuth } from "./useAuth";

export const useMyFetch = () => {
  const { getToken, deleteAuthData } = useAuth();

  const myFetch = async (
    path: string,
    options: {
      method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
      urlParams?: {
        [key: string]: string | string[];
      };
      headers?: {
        [key: string]: string;
      };
      body?: any;
      filters?: {
        [key: string]: string;
      };
    },
  ) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      ...options.headers,
    };

    const urlParams = options.urlParams
      ? "?" +
        Object.keys(options.urlParams)
          .map((key) => `${key}=${options.urlParams?.[key]}`)
          .join("&")
      : "";

    return fetch(`${API_BASE_URL}/api${path}${urlParams}`, {
      method: options.method || "GET",
      headers,
      body: options.body ? JSON.stringify(options.body) : undefined,
    }).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 401) {
          deleteAuthData();
        }
        return Promise.reject(data);
      }
      return data;
    });
  };

  return {
    myFetch,
  };
};
