import type { Capability } from "./app/Capabilities";
import type { Event } from "./app/Events";
import type { Participation } from "./app/Events/EventDetail";
import type { Operator } from "./app/Events/EventDetail/Operators";
import type { Subscription } from "./app/Events/EventDetail/Subscriptions";
import type { Place } from "./app/Places";
import type { Area } from "./app/Places/PlaceDetail/AreasTabContent";
import { StatisticsFilters } from "./app/Statistics";
import type { Student } from "./app/Students";
import type { User } from "./app/Users";
import { TableSettings } from "./components/Table";
import { useMyFetch } from "./hooks/useMyFetch";

export const useApis = () => {
  const { myFetch } = useMyFetch();

  const getUrlParamsFromTableSettings = (
    tableSettings: TableSettings | undefined,
  ) => {
    let urlParams: { [key: string]: string } = {};

    if (!!tableSettings) {
      const settings = tableSettings as TableSettings;
      if (!!settings.sorting?.field) {
        urlParams["sort"] = `${settings.sorting.order === 1 ? "" : "-"}${
          settings.sorting.field
        }`;
      }

      if (!!settings.filters) {
        for (const key in settings.filters) {
          if (typeof settings.filters[key]?.value === "boolean") {
            const beField = settings.filters[key].beField;
            urlParams[`filter[${beField}]`] = settings.filters[key]?.value
              ? "true"
              : "false";
          } else if (typeof settings.filters[key]?.value === "number") {
            const beField = settings.filters[key].beField;
            urlParams[`filter[${beField}]`] = settings.filters[key]?.value;
          } else if (!!settings.filters[key]?.value) {
            const beField = settings.filters[key].beField;
            urlParams[`filter[${beField}]`] = settings.filters[key]?.value;
          }
        }
      }

      if (!!settings.pagination) {
        const page = settings.pagination.first / settings.pagination.rows + 1;
        urlParams["page"] = page.toString();
        urlParams["pageSize"] = settings.pagination.rows.toString();
      }

      if (!!settings.additional) {
        urlParams = { ...urlParams, ...settings.additional };
      }
    }

    return urlParams;
  };

  const login = (data: { email: string; password: string }) =>
    myFetch("/login", { method: "POST", body: data });

  const logout = (data: any) =>
    myFetch("/logout", { method: "POST", body: data });

  const sendPasswordResetEmail = (email: string) =>
    myFetch("/forgot-password", { method: "POST", body: { email } });

  const getUsers = () => myFetch("/users", { method: "GET" });

  const getAdvisors = () =>
    myFetch("/users", {
      method: "GET",
      urlParams: {
        "filter[role]": "advisor",
      },
    });

  const getEventOperators = () =>
    myFetch("/users", {
      method: "GET",
      urlParams: {
        "filter[role]": "eventOperator",
      },
    });

  const getUser = (id: string) => myFetch(`/users/${id}`, { method: "GET" });

  const setNewPassword = (data: {
    password: string;
    password_confirmation: string;
    token: string;
    email: string;
  }) => myFetch("/new-password", { method: "POST", body: data });

  const createUser = (data: Omit<User, "id">) =>
    myFetch("/users", {
      method: "POST",
      body: data,
    });

  const updateUser = (data: User) =>
    myFetch(`/users/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const deleteUser = (id: string) =>
    myFetch(`/users/${id}`, { method: "DELETE" });

  const syncUsers = () => myFetch("/users/sync", { method: "POST" });

  const rescheduleBooking = (data: {
    bookingId: number;
    availabilityId: number;
    date: Date;
    duration: number;
  }) =>
    myFetch(`/bookings/reschedule/${data.bookingId}`, {
      method: "PUT",
      body: data,
    });

    const publicDeleteBooking = async ({
      hashedId,
      origin,
    }: {
      id?: string;
      hashedId?: string;
      origin: "embeddedForm" | "backoffice";
    }) => {
  
      return myFetch(`/public-bookings/${hashedId}`, { method: "DELETE", body: { id: hashedId, origin } });
    }

  const deleteBooking = async ({
    id,
    origin,
  }: {
    id?: string;
    origin: "embeddedForm" | "backoffice";
  }) => {

    return myFetch(`/bookings/${id}`, { method: "DELETE", body: { id: id, origin } });
  }

  const getCapabilities = ({
    queryKey: [_queryKey, tableSettings],
  }: {
    queryKey: (string | TableSettings)[];
  }) => {
    const urlParams: { [key: string]: string } = {
      include: "user,availabilities",
      ...getUrlParamsFromTableSettings(
        tableSettings as TableSettings | undefined,
      ),
    };

    return myFetch("/capabilities", {
      method: "GET",
      urlParams,
    });
  };

  const getCapability = (id: string) =>
    myFetch(`/capabilities/${[parseInt(id)]}`, {
      method: "GET",
      urlParams: {
        include: "user",
      },
    });

  const createCapability = (data: Omit<Capability, "id">) =>
    myFetch("/capabilities", {
      method: "POST",
      body: data,
    });

  const updateCapability = (data: Capability) =>
    myFetch(`/capabilities/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const updateCapabilityVisibility = (data: { id: string; visible: boolean }) =>
    myFetch(`/capabilitiesvisibility/${data.id}`, {
      method: "POST",
      body: {
        id: data.id,
        visible: data.visible,
      },
    });

  const deleteCapability = (id: string) =>
    myFetch(`/capabilities/${id}`, { method: "DELETE" });

  const getCountryGroups = () =>
    myFetch("/countrygroups", {
      method: "GET",
      urlParams: { include: "countries" },
    });

  const getCountries = () =>
    myFetch("/countries", {
      method: "GET",
      urlParams: {
        include: "countryGroup",
      },
    });

  const getProvinces = () => myFetch("/provinces", { method: "GET" });

  const getRegions = () => myFetch("/regions", { method: "GET" });

  const getCities = () => myFetch("/cities", { method: "GET" });

  const getLanguages = () => myFetch("/languages", { method: "GET" });

  const getTimezones = () => myFetch("/timezones", { method: "GET" });

  const getPlaces = ({
    queryKey: [_queryKey, tableSettings],
  }: {
    queryKey: (string | TableSettings)[];
  }) => {
    const urlParams: { [key: string]: string } = {
      include: "city",
      ...getUrlParamsFromTableSettings(
        tableSettings as TableSettings | undefined,
      ),
    };

    return myFetch("/places", {
      method: "GET",
      urlParams,
    });
  };

  const getMiddlewareCalls = ({
    queryKey: [_queryKey, tableSettings],
  }: {
    queryKey: (string | TableSettings)[];
  }) => {
    const urlParams: { [key: string]: string } = {
      ...getUrlParamsFromTableSettings(
        tableSettings as TableSettings | undefined,
      ),
    };

    return myFetch("/middlewarecalls", {
      method: "GET",
      urlParams,
    });
  };

  const getBusinessUnits = () =>
    myFetch("/places", {
      method: "GET",
      urlParams: {
        include: "city",
        "filter[useAsBusinessUnit]": "true",
      },
    });

  const getPlace = (id: string) => myFetch(`/places/${id}`, { method: "GET" });

  const createPlace = (
    data: Omit<Place, "id" | "translations"> & { translations: string },
  ) =>
    myFetch("/places", {
      method: "POST",
      body: data,
    });

  const updatePlace = (
    data: Omit<Place, "translations"> & { translations: string },
  ) =>
    myFetch(`/places/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const deletePlace = (id: string) =>
    myFetch(`/places/${id}`, { method: "DELETE" });

  const getAreas = (place_id?: string) =>
    myFetch(
      "/areas",
      place_id
        ? {
            method: "GET",
            urlParams: {
              "filter[place_id]": place_id,
            },
          }
        : { method: "GET" },
    );

  const getArea = (id: string) => myFetch(`/areas/${id}`, { method: "GET" });

  const createArea = (data: Omit<Area, "id">) =>
    myFetch("/areas", { method: "POST", body: data });

  const updateArea = (data: Area) =>
    myFetch(`/areas/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const deleteArea = (id: string) =>
    myFetch(`/areas/${id}`, { method: "DELETE" });

  const getEvents = ({
    queryKey: [_queryKey, tableSettings],
  }: {
    queryKey: (string | TableSettings)[];
  }) => {
    const urlParams: { [key: string]: string } = {
      include: "place",
      ...getUrlParamsFromTableSettings(
        tableSettings as TableSettings | undefined,
      ),
    };

    return myFetch("/events", {
      method: "GET",
      urlParams,
    });
  };

  const getEvent = (id: string) =>
    myFetch(`/events/${id}`, {
      method: "GET",
      urlParams: {
        include: "place",
      },
    });

  const createEvent = (data: Omit<Event, "id">) =>
    myFetch("/events", {
      method: "POST",
      body: data,
    });

  const updateEvent = (data: Event) =>
    myFetch(`/events/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const deleteEvent = (id: string) =>
    myFetch(`/events/${id}`, { method: "DELETE" });

  const getSubscriptions = ({
    queryKey: [_queryKey, tableSettings],
  }: {
    queryKey: (string | TableSettings)[];
  }) => {
    const urlParams: { [key: string]: string } = {
      include: "event,student",
      ...getUrlParamsFromTableSettings(
        tableSettings as TableSettings | undefined,
      ),
    };

    return myFetch("/subscriptions", {
      method: "GET",
      urlParams,
    });
  };

  const getSubscription = (id: string) =>
    myFetch(`/subscriptions/${id}`, {
      method: "GET",
      urlParams: {
        include: "event,student",
      },
    });

  const getSubscriptionRecap = (hashedId: string) => 
    myFetch(`/subscriptions/${hashedId}/recap`, {
      method: "GET",
    });
  

  const getBookingRecap = (hashedId: string) => 
     myFetch(`/bookings/${hashedId}/recap`, {
      method: "GET",
    });  

  const createSubscription = (
    data: {
      subscription: any;
      student: any;
    } & {
      createdOn?: string;
      marketingConsent?: boolean;
      profilingConsent?: boolean;
      recaptchaToken?: string;
      gtm?: any;
    },
  ) =>
    myFetch("/subscriptions", {
      method: "POST",
      body: data,
    });

  const updateSubscription = (data: Subscription) =>
    myFetch(`/subscriptions/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const resendMailSubscription = (id: string) =>
    myFetch(`/subscriptions/${id}/resendMail`, {
      method: "PUT",
    });

  const getQrCodeSubscription = (id: string) =>
    myFetch(`/subscriptions/${id}/qrCode`, {
      method: "GET",
    });

  const deleteSubscription = (id: string) =>
    myFetch(`/subscriptions/${id}`, { method: "DELETE" });

  const getOperators = (urlParams: { [key: string]: string }) =>
    myFetch("/operators", {
      method: "GET",
      urlParams: {
        include: "user",
        ...urlParams,
      },
    });

  const getOperator = (id: string) =>
    myFetch(`/operators/${id}`, {
      method: "GET",
      urlParams: {
        include: "user",
      },
    });

  const createOperator = (data: Omit<Operator, "id">) =>
    myFetch("/operators", {
      method: "POST",
      body: data,
    });

  const updateOperator = (data: Operator) =>
    myFetch(`/operators/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const deleteOperator = (id: string) =>
    myFetch(`/operators/${id}`, { method: "DELETE" });

  const getParticipations = ({
    queryKey: [_queryKey, tableSettings],
  }: {
    queryKey: (string | TableSettings)[];
  }) => {
    const urlParams: { [key: string]: string } = {
      include: "subscription,operator,operator.user,subscription.student",
      ...getUrlParamsFromTableSettings(
        tableSettings as TableSettings | undefined,
      ),
    };

    return myFetch("/participations", {
      method: "GET",
      urlParams,
    });
  };

  const getParticipation = (id: string) =>
    myFetch(`/participations/${id}`, {
      method: "GET",
      urlParams: {
        include: "subscription,operator,operator.user",
      },
    });

  const createParticipation = (data: Omit<Participation, "id" | "time">) =>
    myFetch("/participations", {
      method: "POST",
      body: data,
    });

  const updateParticipation = (data: Participation) =>
    myFetch(`/participations/${data.id}`, {
      method: "PUT",
      body: data,
    });

  const deleteParticipation = (id: string) =>
    myFetch(`/participations/${id}`, { method: "DELETE" });

  const getUserOperator = (user_id: string, event_id: string) =>
    myFetch(`/operators`, {
      method: "GET",
      urlParams: {
        "filter[user_id]": user_id,
        "filter[event_id]": event_id,
      },
    });

  const getStudents = (urlParams?: { [key: string]: string }) =>
    myFetch("/students", {
      method: "GET",
      urlParams: {
        ...urlParams,
      },
    });

  const getStudent = (id: string) =>
    myFetch(`/students/${id}`, {
      method: "GET",
    });

  const createStudent = (
    data: Omit<Student, "id"> & {
      courseProductCode?: string;
      marketingConsent?: boolean;
      profilingConsent?: boolean;
      recaptchaToken?: string;
    },
  ) => myFetch("/students", { method: "POST", body: data });

  const updateStudent = (data: Student) =>
    myFetch(`/students/${data.id}`, { method: "PUT", body: data });

  const deleteStudent = (id: string) =>
    myFetch(`/students/${id}`, { method: "DELETE" });

  const getAllDegreeLevels = () =>
    myFetch("/degreelevels", {
      method: "GET",
      urlParams: {
        include: "courses",
      },
    });

  const getCurrentDegreeLevels = () =>
    myFetch("/degreelevels", {
      method: "GET",
      urlParams: {
        include: "courses",
        currentCourses: "true",
      },
    });

  const getCourses = ({
    queryKey: [_queryKey, filter],
  }: {
    queryKey: (
      | string
      | { startYear: number; businessUnitCode: string; degree_level_id: string }
    )[];
  }) => {
    const filterValue = filter as {
      startYear: number;
      businessUnitCode: string;
      degree_level_id: string;
    };
    const urlParams: {
      [key: string]: string;
    } = {
      "filter[startYear]": filterValue.startYear.toString(),
    };
    if (!!filterValue.businessUnitCode) {
      urlParams["filter[businessUnitCode]"] = filterValue.businessUnitCode;
    }
    if (!!filterValue.degree_level_id) {
      urlParams["filter[degree_level_id]"] = filterValue.degree_level_id;
    }
    return myFetch("/courses", {
      method: "GET",
      urlParams,
    });
  };

  const getCoursesYears = () => myFetch("/coursesYears", { method: "GET" });

  const getAllCourses = () => myFetch("/courses", { method: "GET" });

  const getCurrentCourses = () =>
    myFetch("/courses", {
      method: "GET",
      urlParams: { currentCourses: "true" },
    });

  const syncCourses = () => myFetch("/courses/sync", { method: "POST" });

  const updateCourseVisibility = (data: {
    id: string;
    visible: boolean;
    type: "single" | "all";
    platform: "booking" | "openDay";
  }) =>
    myFetch(`/coursesvisibility/${data.id}`, {
      method: "POST",
      body: {
        id: data.id,
        visible: data.visible,
        type: data.type,
        platform: data.platform,
      },
    });

  const updateCourseVisibilityBulk = (data: {
    degreeLevelId: string[];
    visible: boolean;
    startYear?: string;
    platform: "booking" | "openDay";
  }) =>
    myFetch(`/coursesvisibilitybulk`, {
      method: "POST",
      body: {
        degreeLevelId: data.degreeLevelId,
        visible: data.visible,
        startYear: data.startYear,
        platform: data.platform,
      },
    });

  const updateCourseTranslations = (data: {
    curriculumCode: string;
    translations: { [key: string]: string };
  }) =>
    myFetch(`/courses-translations`, {
      method: "POST",
      body: data,
    });

  const updateDegreeLevelTranslations = (data: {
    id: string;
    translations: { [key: string]: string };
  }) =>
    myFetch(`/degree-levels-translations`, {
      method: "POST",
      body: data,
    });

  const getStatistics = ({
    queryKey: [_queryKey, filter],
  }: {
    queryKey: (string | StatisticsFilters)[];
  }) => {
    const urlParams: { [key: string]: string | string[] } = {};
    if ((filter as StatisticsFilters)?.dateFrom) {
      urlParams["dateFrom"] = (filter as StatisticsFilters).dateFrom!;
    }
    if ((filter as StatisticsFilters)?.dateTo) {
      urlParams["dateTo"] = (filter as StatisticsFilters).dateTo!;
    }
    if ((filter as StatisticsFilters)?.advisorIds) {
      urlParams["advisorIds"] = (filter as StatisticsFilters).advisorIds!;
    }
    if ((filter as StatisticsFilters)?.countryGroupIds) {
      urlParams["countryGroupIds"] = (
        filter as StatisticsFilters
      ).countryGroupIds!;
    }
    if ((filter as StatisticsFilters)?.appointmentTypes) {
      urlParams["appointmentTypes"] = (
        filter as StatisticsFilters
      ).appointmentTypes!;
    }
    if ((filter as StatisticsFilters)?.courseIds) {
      urlParams["courseIds"] = (filter as StatisticsFilters).courseIds!;
    }
    return myFetch("/statistics", {
      method: "GET",
      urlParams,
    });
  };

  const getAvailabilities = () => myFetch("/availabilities", { method: "GET" });

  const getBooking = (hashedId: string) =>
    myFetch(`/bookings/${hashedId}`, {
          method: "GET",
          urlParams: {
            include: "student",
          },
        });
  

  const createBookingStudent = (data: any) =>
    myFetch("/bookings/createStudent", {
      method: "POST",
      body: data,
    });

  const createBookingFromEmbeddedForm = (data: any) =>
    myFetch("/bookings/createBooking", {
      method: "POST",
      body: data,
    });

  const retryMiddlewareCall = (id: string) =>
    myFetch(`/middlewarecalls/${id}/retry`, {
      method: "POST",
    });

  const completeManuallyMiddlewareCall = (id: string) =>
    myFetch(`/middlewarecalls/${id}/completemanually`, {
      method: "POST",
    });

  return {
    login,
    logout,
    sendPasswordResetEmail,
    setNewPassword,
    getUsers,
    getAdvisors,
    getEventOperators,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    deleteBooking,
    publicDeleteBooking,
    syncUsers,
    getCapabilities,
    getCapability,
    createCapability,
    updateCapability,
    updateCapabilityVisibility,
    deleteCapability,
    getCountryGroups,
    getCountries,
    getProvinces,
    getRegions,
    getLanguages,
    getTimezones,
    getCities,
    getPlaces,
    getBusinessUnits,
    getPlace,
    createPlace,
    updatePlace,
    deletePlace,
    getAreas,
    getArea,
    createArea,
    updateArea,
    deleteArea,
    getEvents,
    getEvent,
    createEvent,
    updateEvent,
    deleteEvent,
    getSubscriptions,
    getSubscription,
    createSubscription,
    updateSubscription,
    resendMailSubscription,
    getQrCodeSubscription,
    deleteSubscription,
    getOperators,
    getOperator,
    createOperator,
    updateOperator,
    deleteOperator,
    getParticipations,
    getParticipation,
    createParticipation,
    updateParticipation,
    deleteParticipation,
    getUserOperator,
    getStudents,
    getStudent,
    createStudent,
    updateStudent,
    deleteStudent,
    getAllDegreeLevels,
    getCurrentDegreeLevels,
    getCourses,
    getCoursesYears,
    getAllCourses,
    getCurrentCourses,
    syncCourses,
    updateCourseVisibility,
    updateCourseTranslations,
    updateCourseVisibilityBulk,
    updateDegreeLevelTranslations,
    getStatistics,
    getAvailabilities,
    rescheduleBooking,
    getBooking,
    createBookingStudent,
    createBookingFromEmbeddedForm,
    getMiddlewareCalls,
    retryMiddlewareCall,
    completeManuallyMiddlewareCall,
    getSubscriptionRecap,
    getBookingRecap,
  };
};
